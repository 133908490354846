import Axios from "axios";

/* eslint-disable no-underscore-dangle */
// export const apiUrlBase = (window._CONFIGS && window._CONFIGS.apiURL) || "http://localhost";
export const apiUrlBase = (window._CONFIGS && window._CONFIGS.apiURL) || "https://store.anisen.eu";
// export const apiUrlBase = (window._CONFIGS !== undefined && window._CONFIGS.apiURL !== undefined) ? window._CONFIGS.apiURL : "https://store.anisen.fr"

export const apiAuth = "auth";
export const apiStats = "stats";
export const apiFiles = "files";
export const apiModules = "modules";
export const apiAdmin = "admin";
export const apiSync = "sync";
export const apiEval = "eval";
export const apiTraining = "training";
export const apiPDF = "pdf";
export const apiActivities = `${apiModules}/activities`;

const { localStorage } = window;

let authToken = localStorage.getItem("token");

export function setAuthToken(newToken) {
  authToken = newToken;
  localStorage.setItem("token", authToken);
  return authToken;
}

const axiosApi = Axios.create({
  baseURL: apiUrlBase,
  headers: {
    "X-Api-Version": 10,
    "X-Api-Key": "4cc4c792-465e-11e9-8a0f-0242ac120002",
  },
});

// add token on each request
axiosApi.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers["X-Auth-Token"] = authToken;
  return config;
});

export default axiosApi;
