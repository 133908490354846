import * as apiPasswordPolicy from "@/api/passwordPolicy";

// eslint-disable-next-line import/prefer-default-export
export async function getPasswordPolicy(accessId, resetToken) {
  const data = await apiPasswordPolicy.get(accessId, resetToken);
  const policy = {
    id: data.ppol_id,
    criteria: data.ppol_criteria,
  };
  if (policy.criteria.special.all)
    policy.criteria.special.include = ["-", "+", "!", "*", "$", "@", "%", "_", ".", ",", "?"];
  else policy.criteria.special.include = policy.criteria.special.include.split(",");
  return policy;
}

export async function getForcedChangePassword() {
  const forceChangePwd = await apiPasswordPolicy.getChangePwd();
  return forceChangePwd.acc_enforced_change;
}
