import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

// Recursive find files in {srcDir}/store
const files = require.context("@/store", true, /^\.\/.*\.(js|ts)$/);
const filenames = files.keys();

// Store
let storeData = {};

// Dynamically require module
function getModule(filename) {
  const file = files(filename);
  const module = file.default || file;
  if (module.commit) {
    throw new Error(
      `[nuxt] store/${filename.replace(
        "./",
        "",
      )} should export a method which returns a Vuex instance.`,
    );
  }
  if (module.state && typeof module.state !== "function") {
    throw new Error(`[nuxt] state should be a function in store/${filename.replace("./", "")}`);
  }
  return module;
}

function getModuleNamespace(sd, namePath) {
  if (namePath.length === 1) {
    return sd.modules;
  }
  const namespace = namePath.shift();
  // eslint-disable-next-line no-param-reassign
  sd.modules[namespace] = sd.modules[namespace] || {};
  // eslint-disable-next-line no-param-reassign
  sd.modules[namespace].namespaced = true;
  // eslint-disable-next-line no-param-reassign
  sd.modules[namespace].modules = sd.modules[namespace].modules || {};
  return getModuleNamespace(sd.modules[namespace], namePath);
}

// Check if store/index.js exists
let indexFilename;
filenames.forEach((filename) => {
  if (filename.indexOf("./index.") !== -1) {
    indexFilename = filename;
  }
});
if (indexFilename) {
  storeData = getModule(indexFilename);
}

// If store is not an exported method = modules store
if (typeof storeData !== "function") {
  // Store modules
  if (!storeData.modules) {
    storeData.modules = {};
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const filename of filenames) {
    let name = filename.replace(/^\.\//, "").replace(/\.(js|ts)$/, "");
    // eslint-disable-next-line no-continue
    if (name === "index") continue;

    const namePath = name.split(/\//);
    const module = getModuleNamespace(storeData, namePath);

    name = namePath.pop();
    module[name] = getModule(filename);
    module[name].namespaced = true;
  }
}

// createStore
export default storeData instanceof Function
  ? storeData
  : () =>
      new Vuex.Store({
        strict: process.env.NODE_ENV !== "production",
        ...storeData,
        state: storeData.state instanceof Function ? storeData.state() : {},
      });
