/* eslint-disable import/prefer-default-export */
import api, { apiAuth } from "@/api";

export async function get(accessId, resetToken) {
  const { data } = await api.post(`${apiAuth}/getPasswordPolicy`, { access: accessId, resetToken });
  return data;
}

export async function getChangePwd() {
  const { data } = await api.post(`${apiAuth}/getForcedChangePassword`, {});
  return data;
}
