/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import api, { apiAdmin, apiStats } from "@/api";
// import { wait } from '~/misc/utils'

export const state = () => ({
  // New version
  typeUsers: "resident",
  updateDates: {
    participant_update: null,
    staff_update: null,
  },

  listResidents: [],
  listResidentsArchived: [],
  listSupervisors: [],
  listSupervisorsArchived: [],
  listAllResidents: [],
  listAllSupervisors: [],
  listAllAccess: [],
  changeListUser: false,
});

export const mutations = {
  // New V2
  setUpdateDates: (data, val) => (data.updateDates = val),
  setTypeUser: (data, type) => (data.typeUsers = type),
  setResidents: (data, val) => (data.listResidents = val),
  setResidentsArchived: (data, val) => (data.listResidentsArchived = val),
  setSupervisors: (data, val) => (data.listSupervisors = val),
  setSupervisorsArchived: (data, val) => (data.listSupervisorsArchived = val),
  setAllResidents: (data, val) => (data.listAllResidents = val),
  setAllSupervisors: (data, val) => (data.listAllSupervisors = val),
  setAllAccess: (data, val) => (data.listAllAccess = val),
  setChangeListUser: (data, val) => (data.changeListUser = val),
};

export const getters = {};

export const actions = {
  // New version
  async chooseTypeResident({ commit }) {
    commit("setTypeUser", "resident");
  },
  async getUpdateDates({ commit }) {
    const { data } = await api.post(`${apiStats}/getUpdateDates`, {});
    commit("setUpdateDates", data);
    return { data };
  },
  async chooseTypeSupervisor({ commit }) {
    commit("setTypeUser", "supervisor");
  },
  async getAllAccess({ commit }) {
    const { data } = await api.post(`${apiAdmin}/getallaccess`, {});

    const ordered = data.sort((a, b) =>
      // eslint-disable-next-line no-nested-ternary
      a.acc_login > b.acc_login ? 1 : b.acc_login > a.acc_login ? -1 : 0,
    );
    commit("setAllAccess", ordered);
    return data;
  },
  async removeAccess(_, formData) {
    await api.post(`${apiAdmin}/removeAccess`, formData);
    return "OK";
  },
  async addAccess(_, formData) {
    const { data } = await api.post(`${apiAdmin}/setaccess`, formData);
    return data;
  },
  async resetChangeListUser({ commit }) {
    commit("setChangeListUser", false);
  },
  checkResidentExist(_, parId) {
    api.post(`${apiAdmin}/getIfParticipant`, { par_id: parseInt(parId, 10) });
  }
};
