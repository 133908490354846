import "@babel/polyfill";
import { sync } from "vuex-router-sync";
import _ from "lodash";
import Vue from "vue";
import Meta from "vue-meta";
import VTooltip from "v-tooltip";
import VeeValidate, { Validator } from "vee-validate";
import validationMessages from "vee-validate/dist/locale/fr";
import Notifications from "vue-notification";
import "@fortawesome/fontawesome-free/css/all.css";
import { BootstrapVue } from "bootstrap-vue";
import VCalendar from "v-calendar";
import VueMask from "v-mask";
import VueI18n from "vue-i18n";

import api from "@/api";
import { GetAllLabels, GetPagesMaintenance } from "@/services/global";
import { getForcedChangePassword } from "@/services/passwordPolicy";
import createRouter from "@/misc/createRouter";
import createStore from "@/misc/createStore";
import { initStore } from "@/misc/utils";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/scss/gestion.scss";

// Load locally as a function.

// Use v-calendar & v-date-picker components
Vue.use(VCalendar);
Vue.use(BootstrapVue);
Vue.use(VueMask);
Vue.use(VueI18n);
const moment = require("moment-timezone");

moment.tz.setDefault("Europe/Paris");
require("moment/locale/fr");

Vue.use(require("vue-moment"), {
  moment,
});

Vue.use(VeeValidate, {
  dictionary: {
    fr: validationMessages,
  },
});

const i18n = new VueI18n(
  {
    locale: "fr",
    messages: {},
  },
  {
    locale: "en",
    messages: {},
  },
);

// TODO: Intégration avec i18n à faire
const dictionary = {
  custom: {
    "confirmation du mot de passe": {
      confirmed: "La confirmation du mot de passe ne correspond pas.",
    },
  },
};

Validator.localize("fr", dictionary);
Vue.use(Notifications);
Vue.use(VTooltip);
Vue.use(Meta, {
  keyName: "head",
  attribute: "data-n-head",
  ssrAttribute: "data-n-head-ssr",
  tagIDKeyName: "hid",
});

// eslint-disable-next-line
const store = createStore();
const router = createRouter();
initStore(store);
sync(store, router);

let pagesMaintenance;
let forceChangePassword;

async function loadPagesMaintenance() {
  pagesMaintenance = (await GetPagesMaintenance()) || [];
}

async function isChangePasswordForced() {
  forceChangePassword = await getForcedChangePassword();
}

function setAlertPassword() {
  return store.dispatch("alert", {
    text: i18n.t("alert_password"),
  });
}

loadPagesMaintenance();
isChangePasswordForced();

// const pagesMaintenance = loadPagesMaintenance()
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  if (to.name === "login") {
    if (store.state.account.user && store.state.account.user.acc_consent) {
      return next(from.query.from || "/");
    }
    return next();
  }
  if (
    to.path !== "/changePassword" &&
    forceChangePassword &&
    to.name !== "index" &&
    to.name !== "logout"
  ) {
    return router.push({
      name: "changePassword",
      params: {
        accId: store.state.account.user.acc_id,
        accLogin: store.state.account.user.acc_login,
      },
    });
  }
  // test s'il y ades pages en maintenance
  if (pagesMaintenance?.length > 0) {
    // Si ça correspond à la page demandé
    if (pagesMaintenance.some((p) => p.name === to.name)) {
      const data = pagesMaintenance.find((p) => p.name === to.name);
      // SI ou on réoriente avec push pour ne pas avoir d'erreur de redirection
      return router.push({
        name: "maintenance",
        params: {
          title: data.title,
          description: data.description,
          head_title: data.headTitle,
          head_breads: data.headBreads,
          page_origin: to,
        },
        query: { original: to.path },
      });
    }
  }
  if (to.name === "print-calendar") {
    if (to.query.token) {
      store.commit("account/setAuthToken", to.query.token);
      return next();
    }
    return next({ path: "/login" });
  }
  if (!store.state.account.user /* || !store.state.account.user.acc_consent */) {
    if (to.path === "/account" && to.query.updatepasswd) {
      return next();
    }
    if (to.path === "/changePassword") {
      return next();
    }
    return next({
      path: "/login",
      query: { redirect: to.fullPath !== "/" ? to.fullPath : undefined },
    });
  }
  if (store.state.account.user && !store.state.account.user.acc_consent && to.name !== "login") {
    return next({ path: "/login" });
  }
  if (to.name !== "logout" && to.path !== "/account" && store.state.account.user.mustUpdatePasswd) {
    setTimeout(setAlertPassword(), 1000);
    return next({
      path: "/account",
      query: { from: to.path, updatepasswd: "" },
    });
  }
  store.dispatch("stat/getUpdateDates");
  next();
});

api.interceptors.response.use(
  (resp) => resp,
  (error) => {
    if (
      _.get("response.data.errorType", error) === "UNAUTHENTICATED" &&
      router.currentRoute.name !== "login" &&
      router.currentRoute.name !== "changePassword"
    ) {
      router.push({ path: "/logout" });
    }
    if (
      error.response &&
      error.response.status === 401 &&
      router.currentRoute.name && // route not matched yet
      router.currentRoute.name !== "login" &&
      router.currentRoute.name !== "changePassword"
    ) {
      store.commit("account/setAccount", null);
      store.commit("account/setAuthToken", null);
      localStorage.removeItem("perm");
      router.push({
        path: "/login",
        query: {
          redirect: router.currentRoute.fullPath !== "/" ? router.currentRoute.fullPath : undefined,
        },
      });
    }
    if (error.response && error.response.status === 404) {
      router.push({ name: "Ans404", params: { message: error.response.data } });
    }
    return Promise.reject(error);
  },
);

router.onReady(() => {
  if (router.currentRoute.path === "/account" && store.state.account.user.mustUpdatePasswd) {
    setTimeout(setAlertPassword(), 1000);
  }
});

const vm = new Vue({
  i18n,
  mounted() {
    const el = document.getElementById("spinner");
    el.parentNode.removeChild(el);
  },
  beforeCreate() {
    this.$store.commit("account/initData");
  },
  render() {
    return (
      <div id="app">
        <keep-alive>
          <router-view />
        </keep-alive>
      </div>
    );
  },
  router,
  store,
});

async function loadMessages() {
  const context = require.context("./locales", true, /[a-z0-9-_]+\.json$/i);
  let labels;
  if (store.state.account.user) labels = await GetAllLabels();
  const messages = context
    .keys()
    .map((key) => ({
      key,
      locale: key.match(/[a-z0-9-_]+/i)[0],
    }))
    .reduce(
      (result, { key, locale }) => ({
        ...result,
        [locale]: context(key),
      }),
      {},
    );
  if (labels != null) {
    labels = labels[0].labels;

    Object.keys(messages.fr).forEach((messageKey) => {
      if (messageKey in labels) {
        messages.fr[messageKey] = labels[messageKey];
      }
    });
  }
  return { context, messages };
}

function loadDefaultMessage() {
  const context = require.context("./locales", true, /[a-z0-9-_]+\.json$/i);

  const messages = context
    .keys()
    .map((key) => ({
      key,
      locale: key.match(/[a-z0-9-_]+/i)[0],
    }))
    .reduce(
      (result, { key, locale }) => ({
        ...result,
        [locale]: context(key),
      }),
      {},
    );

  return { context, messages };
}

loadMessages().then(
  (response) => {
    // On traite ici les cles de chaque pays et on insère les messages de chaque langues
    Object.keys(response.messages).forEach((locale) => {
      i18n.setLocaleMessage(locale, response.messages[locale]);
    });
    vm.$mount("#app");
  },
  () => {
    const { messages } = loadDefaultMessage();
    Object.keys(messages).forEach((locale) => {
      i18n.setLocaleMessage(locale, messages[locale]);
    });
    vm.$mount("#app");
  },
);

const context = require.context("./locales", true, /[a-z0-9-_]+\.json$/i);
if (module.hot) {
  module.hot.accept(context.id, () => {
    // Rechargement des labels
    loadMessages().then((response) => {
      Object.keys(response.messages).forEach((locale) => {
        i18n.setLocaleMessage(locale, response.messages[locale]);
      });
    });
  });
}

export default i18n;
