/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import _ from "lodash";

export const state = () => ({
  actionbarComponent: null,
  scrollPosition: 0,
  breadcrumbs: [],
  alerts: [],
});

export const mutations = {
  setActionbarComponent: (data, comp) => (data.actionbarComponent = comp && (() => comp)),
  setBreadcrumb: (data, breadcrumb) => (data.breadcrumbs = breadcrumb),
  alert: (data, alrt) => data.alerts.push(alrt),
  deleteAlert: (data, alrt) => data.alerts.splice(data.alerts.indexOf(alrt, 1), 1),
  setScrollPosition: (data, positionScroll) => (data.scrollPosition = positionScroll),
};

export const actions = {
  alert: (ctx, alrt) => {
    alrt.timeout = setTimeout(() => {
      ctx.commit("deleteAlert", alrt);
    }, alrt.delay || 4000);
    ctx.commit("alert", alrt);
  },
  getScrollPosition: ({ commit }, dataPosition) => {
    commit("setScrollPosition", dataPosition);
    return dataPosition;
  },
};

export const getters = {
  types: (data) => _.groupBy("isCustom", data.types),
};
