/* eslint-disable no-param-reassign */
import api, { apiAdmin } from "@/api";

export const state = () => ({
  permissions: JSON.parse(localStorage.getItem("perm")),
  roles: [],
  roleUser: null,
});

export const mutations = {
  setPermissions(data, perm) {
    localStorage.removeItem("perm");
    if (perm) {
      localStorage.setItem(
        "perm",
        JSON.stringify(
          perm.reduce((map, obj) => {
            map[obj.perm_name] = obj.flags;
            return map;
          }, {}),
        ),
      );
    }
  },
  // eslint-disable-next-line no-return-assign
  setRoles: (data, val) => (data.roles = val),
  // eslint-disable-next-line no-return-assign
  setRole: (data, val) => (data.roleUser = val),
};

export const actions = {
  async getPermissions({ commit }) {
    const result = await api.post(`${apiAdmin}/getaccesspermissions`, {});
    localStorage.removeItem("perm");
    if (result.data.length > 0) {
      commit("setPermissions", result.data[0].permissions);
      commit("setRole", result.data[0].rol_id);
    }
    return result;
  },
  async getallRoles({ commit }) {
    const { data } = await api.post(`${apiAdmin}/getroles`, {});
    commit("setRoles", data);
    return data;
  },
};
