// AJout d'un nombre de mois à une date
export function addMonth(date, month) {
  const newDate = new Date(date);
  newDate.setMonth(newDate.getMonth() + month);
  return newDate;
}

export function addDays(date, days) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

// Arrondi un nombre
export function roundDecimal(nombre, precision) {
  const decimal = precision ?? 2;
  const tmp = Math.pow(10, decimal);
  return Math.round(nombre * tmp) / tmp;
}

/**
 * Convert special characters to normal
 *
 * @param {String} source text
 * @return {String} converted text
 */
export function removeDiacritics(source) {
  return source.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
/**
 * Validate an email format
 *
 * @export
 * @param {string} email the email to validate
 * @return {boolean} true / false
 */
export function validateEmail(email) {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const status = email.match(emailPattern);
  return status && status.length > 0 ? true : false;
}
