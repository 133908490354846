/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import api, { apiAdmin, apiStats } from "@/api";

const moment = require("moment");

export const state = () => ({
  // API-10_OPTIMISATION
  updateDates: {
    permanent_update: null,
    temporary_update: null,
    health_update: null,
    wellbeing_update: null,
    involvement_update: null,
    appreciation_update: null,
    sessions_update: null,
    participants_update: null,
    staffs_update: null,
    perimeters_update: null,
    job_update: null,
    services_update: null,
    groups_update: null,
    domain_update: null,
    external_activities_update: null,
  },
  // Home
  generalInvolvement: null,
  generalInvolvementLastThreeMonths: null,
  generalInvolvementLastYear: null,
  generalWellbeing: null,
  generalWellbeingLastThreeMonths: null,
  generalWellbeingLastYear: null,
  generalSessionsByDomain: null,
  generalExternalSessions: null,

  // Users
  participantsSessions: [],
  staffsSessions: [],

  // Establishment - Residents
  troubles: null,
  statsGeneralSessionsWithTroubles: null,
  statsPatgamesActivitiesWithTroubles: null,
  statsExternalActivitiesWithTroubles: null,

  // Establishment - Staffs
  statsSupervisors: null,
  statsByStaffs: null,

  // Participant - Social
  currentResident: null,
  reportBestActivity: null,
  reportBestActivityExtern: null,
  reportWorstActivity: null,
  reportWorstActivityExtern: null,
  globalAnimations: null,
  participantActivitiesPATGames: null,
  participantActivitiesExternal: null,
  participantGeneralSessionsWithTroubles: null,
  participantPatgamesActivitiesWithTroubles: null,
  participantExternalActivitiesWithTroubles: null,
  participantReportsAvg: null,

  // Reports
  statsReportsAvg: null,

  // Participant - Health
  effectiveTime: null,
  effectiveTimeByMonth: [],
  successRate: null,
  successRateByMonth: null,
  cognitiveWork: null,
  cognitiveWorkByMonth: null,
  synchroState: null,
  excludedActivities: null,

  slidingPeriodParticipant: 3,
  slidingPeriodStatsGeneral: 3,
  slidingDateStartStatsGeneral: null,
  slidingDateEndStatsGeneral: null,
  slidingPeriodStatsSupervisors: 3,
  slidingPeriodStatsParticipants: 3,
});

export const mutations = {
  setUpdateDates: (data, val) => (data.updateDates = val),

  setGeneralInvolvement: (data, val) => (data.generalInvolvement = val),
  setGeneralInvolvementLastThreeMonths: (data, val) =>
    (data.generalInvolvementLastThreeMonths = val),
  setGeneralInvolvementLastYear: (data, val) => (data.generalInvolvementLastYear = val),
  setGeneralWellbeing: (data, val) => (data.generalWellbeing = val),
  setGeneralWellbeingLastThreeMonths: (data, val) => (data.generalWellbeingLastThreeMonths = val),
  setGeneralWellbeingLastYear: (data, val) => (data.generalWellbeingLastYear = val),
  setGeneralSessionsByDomain: (data, val) => (data.generalSessionsByDomain = val),
  setGeneralExternalSessions: (data, val) =>
    (data.generalExternalSessions = val ? val.sort((a, b) => (a.volume > b.volume ? -1 : 1)) : val),

  // Users
  setParticipantsSessions: (data, val) => (data.participantsSessions = val),
  setStaffsSessions: (data, val) => (data.staffsSessions = val),

  setTroubles: (data, val) => (data.troubles = val),
  setGeneralSessionsWithTroubles: (data, val) => (data.statsGeneralSessionsWithTroubles = val),
  setPatgamesActivitiesWithTroubles: (data, val) =>
    (data.statsPatgamesActivitiesWithTroubles = val),
  setExternalActivitiesWithTroubles: (data, val) =>
    (data.statsExternalActivitiesWithTroubles = val),

  setResident: (data, val) => (data.currentResident = val),
  setReportsActivity: (data, val) => {
    data.reportBestActivity = val
      ? capitalizeArray(
          val.filter((e) => e.best && !e.isexternal).sort((a, b) => (a.score > b.score ? -1 : 1)),
        )
      : null;
    data.reportWorstActivity = val
      ? capitalizeArray(
          val.filter((e) => !e.best && !e.isexternal).sort((a, b) => (a.score < b.score ? -1 : 1)),
        )
      : null;
    data.reportBestActivityExtern = val
      ? capitalizeArray(
          val.filter((e) => e.best && e.isexternal).sort((a, b) => (a.score > b.score ? -1 : 1)),
        )
      : null;
    data.reportWorstActivityExtern = val
      ? capitalizeArray(
          val.filter((e) => !e.best && e.isexternal).sort((a, b) => (a.score < b.score ? -1 : 1)),
        )
      : null;
  },
  setGlobalAnimations: (data, val) => (data.globalAnimations = val),
  setParticipantActivitiesPATGames: (data, val) => {
    data.participantActivitiesPATGames = val;
  },
  setParticipantActivitiesExternal: (data, val) => {
    data.participantActivitiesExternal = val;
  },
  setParticipantSessionsWithTroubles: (data, val) =>
    (data.participantGeneralSessionsWithTroubles = val),
  setParticipantPatgamesActivitiesWithTroubles: (data, val) =>
    (data.participantPatgamesActivitiesWithTroubles = val),
  setParticipantExternalActivitiesWithTroubles: (data, val) =>
    (data.participantExternalActivitiesWithTroubles = val),
  setParticipantReportsAvg: (data, val) => (data.participantReportsAvg = val),

  setSynchroState: (data, val) => (data.synchroState = val),
  setExcludedActivities: (data, val) => (data.excludedActivities = val),
  setEffectiveTime: (data, val) => (data.effectiveTime = val),
  setEffectiveTimeByMonth: (data, val) => (data.effectiveTimeByMonth = val),
  setSuccessRate: (data, val) => (data.successRate = val),
  setSuccessRateByMonth: (data, val) => (data.successRateByMonth = val),
  setCognitiveWork: (data, val) => (data.cognitiveWork = val),
  setCognitiveWorkByMonth: (data, val) => (data.cognitiveWorkByMonth = val),

  setStatsReportsAvg: (data, val) => (data.statsReportsAvg = val),

  changeSlidingPeriodGeneral: (data, val) => (data.slidingPeriodStatsGeneral = val),
  changeSlidingDateStartGeneral: (data, val) => (data.slidingDateStartStatsGeneral = val),
  changeSlidingDateEndGeneral: (data, val) => (data.slidingDateEndStatsGeneral = val),
  changeSlidingPeriodParticipant: (data, val) => (data.slidingPeriodParticipant = val),
  changeSlidingPeriodParticipants: (data, val) => (data.slidingPeriodStatsParticipants = val),
  changeSlidingPeriodSupervisors: (data, val) => (data.slidingPeriodStatsSupervisors = val),
};

// Actions
export const actions = {
  async getUpdateDates({ commit }) {
    const { data } = await api.post(`${apiStats}/getUpdateDates`, {});
    commit("setUpdateDates", data);
    return { data };
  },
  async getGeneralInvolvement({ commit, state }, params) {
    let lastThreeMonths = false;
    let lastYear = false;
    const dateParams = getPeriodParams(state);

    if (!params) {
      commit("setGeneralInvolvement", null);
      params = {
        date_start: moment(dateParams.dateS, "DD-MM-YYYY").format("YYYY-MM-DD"),
        date_end: moment(dateParams.dateE, "DD-MM-YYYY").format("YYYY-MM-DD"),
      };
    } else {
      commit("setStatsGeneralInvolvement", null);
      if (typeof params.period !== "undefined") {
        lastThreeMonths = params.period === 3;
        lastYear = params.period === 12;
        dateParams.dateS = moment().subtract(params.period, "months").format("DD-MM-YYYY");
        dateParams.dateE = moment().add(1, "days").format("DD-MM-YYYY");
      }
      params.date_start = moment(dateParams.dateS, "DD-MM-YYYY").format("YYYY-MM-DD");
      params.date_end = moment(dateParams.dateE, "DD-MM-YYYY").format("YYYY-MM-DD");
    }
    const { data } = await api.post(`${apiStats}/getgeneralinvolvement`, params);

    if (data) {
      if (lastThreeMonths) {
        commit("setGeneralInvolvementLastThreeMonths", data[0]);
      }
      if (lastYear) {
        commit("setGeneralInvolvementLastYear", data[0]);
      }
      if (!lastYear && !lastThreeMonths && !params.full) {
        commit("setGeneralInvolvement", data[0]);
      }
      if (params.full) {
        commit("setStatsGeneralInvolvement", data[0]);
      }
    }
    return { data };
  },
  async getGeneralWellbeing({ commit, state }, params) {
    let lastThreeMonths = false;
    let lastYear = false;
    const dateParams = getPeriodParams(state);

    if (!params) {
      commit("setGeneralWellbeing", null);
      commit("setStatsGeneralWellBeing", null);
      params = {
        date_start: moment(dateParams.dateS, "DD-MM-YYYY").format("YYYY-MM-DD"),
        date_end: moment(dateParams.dateE, "DD-MM-YYYY").format("YYYY-MM-DD"),
      };
    } else {
      if (typeof params.period !== "undefined") {
        lastThreeMonths = params.period === 3;
        lastYear = params.period === 12;
        dateParams.dateS = moment().subtract(params.period, "months").format("DD-MM-YYYY");
        dateParams.dateE = moment().add(1, "days").format("DD-MM-YYYY");
      }
      params.date_start = moment(dateParams.dateS, "DD-MM-YYYY").format("YYYY-MM-DD");
      params.date_end = moment(dateParams.dateE, "DD-MM-YYYY").format("YYYY-MM-DD");
    }
    const { data } = await api.post(`${apiStats}/getgeneralwellbeing`, params);

    if (data) {
      const result = data[0].wellbeing ? parseFloat(data[0].wellbeing) : 0.1;
      if (lastThreeMonths) {
        commit("setGeneralWellbeingLastThreeMonths", result);
      }
      if (lastYear) {
        commit("setGeneralWellbeingLastYear", result);
      }
      if (!lastYear && !lastThreeMonths && !params.full) {
        commit("setGeneralWellbeing", result);
      }
      if (params.full) {
        commit("setStatsGeneralWellBeing", result);
      }
    }
    return { data };
  },
  async getGeneralSessionsByDomain({ commit, state }, params) {
    commit("setGeneralSessionsByDomain", null);
    params = getSurchargedParams(state, params);
    const { data } = await api.post(`${apiStats}/getGeneralSessionsByDomain`, params);

    if (data) {
      commit("setGeneralSessionsByDomain", data);
    }
    return { data };
  },
  async getGeneralExternalSessions({ commit, state }, params) {
    commit("setGeneralExternalSessions", null);
    params = getSurchargedParams(state, params);
    const { data } = await api.post(`${apiStats}/getGeneralExternalSessions`, params);

    if (data) {
      commit("setGeneralExternalSessions", data);
    }
    return { data };
  },
  async getGeneralSessionsWithTroubles({ commit, state }, params) {
    commit("setGeneralSessionsWithTroubles", null);
    params = getSurchargedParams(state, params);
    if (params.par_id) {
      commit("setParticipantSessionsWithTroubles", null);
    }
    const { data } = await api.post(`${apiStats}/getStatsTroubleSessions`, params);
    if (params.par_id) {
      commit("setParticipantSessionsWithTroubles", data);
    } else {
      commit("setGeneralSessionsWithTroubles", data);
    }
    return { data };
  },
  async getTroubles({ commit }) {
    const { data } = await api.post(`${apiAdmin}/getTroubles`, {});
    commit("setTroubles", data);
    return { data };
  },
  async getParticipantReportsAvg({ commit, state }, params) {
    commit("setParticipantReportsAvg", null);
    params = getSurchargedParams(state, params);
    const { data } = await api.post(`${apiStats}/getParticipantReportsAvg`, params);
    commit("setParticipantReportsAvg", data);
    return data;
  },
  async getGlobalReportsAvg({ commit, state }, params) {
    commit("setStatsReportsAvg", null);
    params = getSurchargedParams(state, params);
    const { data } = await api.post(`${apiStats}/getGlobalReportsAvg`, params);
    commit("setStatsReportsAvg", data);
    return data;
  },
  async getListParticipantReports({ commit, state }, params) {
    commit("setListParticipantReports", null);
    params = getSurchargedParams(state, params);
    const { data } = await api.post(`${apiStats}/GetStatPartEvaluations`, params);
    commit("setListParticipantReports", data);
    return data;
  },
  async getTopActivitiesGeneral({ commit, state }, params) {
    params = getSurchargedParams(state, params);
    const { data } = await api.post(`${apiStats}/getTopActivities`, params);

    commit("setTopActivitiesGeneral", data);
    return data;
  },
  async getTopExternalActivitiesGeneral({ commit, state }, params) {
    params = getSurchargedParams(state, params);
    const { data } = await api.post(`${apiStats}/getTopExternalActivities`, params);

    commit("setTopExternalActivitiesGeneral", data);
    return data;
  },
  async getResident({ commit, state }, params) {
    commit("setResident", null);
    params = getSurchargedParams(state, params);
    const { data } = await api.post(`${apiAdmin}/getparticipants`, params);
    commit("setResident", data[0]);
    return data[0];
  },
  async getReportsActivity({ commit, state }, id) {
    commit("setReportsActivity", null);
    const dateParams = getPeriodParams(state);

    const params = {
      date_start: moment(dateParams.dateS, "DD-MM-YYYY").format("YYYY-MM-DD"),
      date_end: moment(dateParams.dateE, "DD-MM-YYYY").format("YYYY-MM-DD"),
      par_id: parseInt(id, 10),
    };
    const { data } = await api.post(`${apiStats}/GetReportsActivity`, params);
    commit("setReportsActivity", data);
    return data;
  },
  async getGlobalAnimations({ commit, state }, id) {
    commit("setGlobalAnimations", null);
    const dateParams = getPeriodParams(state);

    const params = {
      date_start: moment(dateParams.dateS, "DD-MM-YYYY").format("YYYY-MM-DD"),
      date_end: moment(dateParams.dateE, "DD-MM-YYYY").format("YYYY-MM-DD"),
      par_id: parseInt(id, 10),
    };
    const { data } = await api.post(`${apiStats}/GetGlobalAnimations`, params);
    commit("setGlobalAnimations", data);
    return data;
  },
  async getParticipantActivities({ commit, state }, id) {
    commit("setParticipantActivitiesPATGames", null);
    commit("setParticipantActivitiesExternal", null);
    const dateParams = getPeriodParams(state);

    const params = {
      date_start: moment(dateParams.dateS, "DD-MM-YYYY").format("YYYY-MM-DD"),
      date_end: moment(dateParams.dateE, "DD-MM-YYYY").format("YYYY-MM-DD"),
      par_id: parseInt(id, 10),
      act_type: "pat",
    };
    const res = await api.post(`${apiStats}/getstatparticipantactivities`, params);
    commit("setParticipantActivitiesPATGames", res.data);

    params.act_type = "external";
    const resExternal = await api.post(`${apiStats}/getstatparticipantactivities`, params);
    commit("setParticipantActivitiesExternal", resExternal.data);
    return res.data;
  },
  async changeSlidingPeriodParticipant({ commit }, data) {
    commit("changeSlidingPeriodParticipant", data);
    return data;
  },
  async getEffectiveTime({ commit, state }, params) {
    commit("setEffectiveTime", null);
    params = getSurchargedParams(state, params);
    const { data } = await api.post(`${apiStats}/getEffectiveTime`, params);
    commit("setEffectiveTime", data);
    return data;
  },
  async getSuccessRate({ commit, state }, params) {
    commit("setSuccessRate", null);
    params = getSurchargedParams(state, params);
    const { data } = await api.post(`${apiStats}/getSuccessRate`, params);

    commit("setSuccessRate", data);
    return data;
  },
  async getCognitiveWork({ commit, state }, params) {
    commit("setCognitiveWork", null);
    params = getSurchargedParams(state, params);
    const { data } = await api.post(`${apiStats}/getCognitiveWork`, params);

    const gnosiaauditive = data.find((mef) => mef.mef_name === "mef_gnosieauditive");
    const gnosiavisual = data.find((mef) => mef.mef_name === "mef_gnosievisual");

    const gnosia =
      (gnosiaauditive ? gnosiaauditive.cognitive_work : 0) +
      (gnosiavisual ? gnosiavisual.cognitive_work : 0);

    const newData = data.filter(
      (e) => e.mef_name !== "mef_gnosieauditive" && e.mef_name !== "mef_gnosievisual",
    );
    if (gnosia > 0) {
      await newData.push({
        mef_id: 0,
        mef_name: "mef_gnosia",
        mef_label: "Gnosie",
        cognitive_work: gnosia,
      });
    }

    newData.sort((a, b) => {
      return a.mef_label.localeCompare(b.mef_label);
    });
    commit("setCognitiveWork", newData);
    return newData;
  },
  async getEffectiveTimeByMonth({ commit, state }, params) {
    commit("setEffectiveTimeByMonth", null);
    params = getSurchargedParams(state, params);
    const { data } = await api.post(`${apiStats}/getEffectiveTimeByMonth`, params);

    commit("setEffectiveTimeByMonth", data);
    return data;
  },
  async getSuccessRateByMonth({ commit, state }, params) {
    commit("setSuccessRateByMonth", null);
    params = getSurchargedParams(state, params);
    const { data } = await api.post(`${apiStats}/getSuccessRateByMonth`, params);

    commit("setSuccessRateByMonth", data);
    return data;
  },
  async getCognitiveWorkByMonth({ commit, state }, params) {
    commit("setCognitiveWorkByMonth", null);
    params = getSurchargedParams(state, params);
    const { data } = await api.post(`${apiStats}/getCognitiveWorkByMonth`, params);

    commit("setCognitiveWorkByMonth", data);
    return data;
  },
  async getSynchroState({ commit }) {
    commit("setSynchroState", null);
    const { data } = await api.post(`${apiStats}/getsynchrostate`, {});
    commit("setSynchroState", data);
    return data;
  },
  async getExcludedActivities({ commit }) {
    commit("setExcludedActivities", null);
    const { data } = await api.post(`${apiStats}/getexcludedactivities`, {});
    commit("setExcludedActivities", data);
    return data;
  },
  async changeSlidingPeriodParticipants({ commit }, data) {
    commit("changeSlidingPeriodParticipants", data);
    return data;
  },
  async changeSlidingPeriodGeneral({ commit }, data) {
    commit("changeSlidingPeriodGeneral", data);
    return data;
  },
  async changeSlidingDateStartGeneral({ commit }, data) {
    commit("changeSlidingDateStartGeneral", data);
    return data;
  },
  async changeSlidingDateEndGeneral({ commit }, data) {
    commit("changeSlidingDateEndGeneral", data);
    return data;
  },
  async changeSlidingPeriodSupervisors({ commit }, data) {
    commit("changeSlidingPeriodSupervisors", data);
    return data;
  },
};

function capitalizeArray(array) {
  const datas = array;
  for (let i = 0; i < datas.length; i += 1) {
    datas[i].act_label =
      datas[i].act_label[0].toUpperCase() + datas[i].act_label.substring(1).toLowerCase();
  }
  return datas;
}

function getPeriodParams(state) {
  let dateS;
  let dateE;
  let p;

  if (!state.slidingDateStartStatsGeneral && !state.slidingDateEndStatsGeneral) {
    p = state.slidingPeriodStatsGeneral;
    dateS = moment().subtract(p, "months").format("DD-MM-YYYY");
    dateE = moment().add(1, "days").format("DD-MM-YYYY");
  } else if (state.slidingDateStartStatsGeneral && state.slidingDateEndStatsGeneral) {
    dateS = state.slidingDateStartStatsGeneral;
    dateE = moment(state.slidingDateEndStatsGeneral, "DD-MM-YYYY")
      .add("1", "days")
      .format("DD-MM-YYYY");
  }
  return { dateS, dateE };
}

function getSurchargedParams(state, params) {
  const dateParams = getPeriodParams(state);

  if (!params) {
    params = {
      date_start: moment(dateParams.dateS, "DD-MM-YYYY").format("YYYY-MM-DD"),
      date_end: moment(dateParams.dateE, "DD-MM-YYYY").format("YYYY-MM-DD"),
    };
  } else {
    params.date_start = moment(dateParams.dateS, "DD-MM-YYYY").format("YYYY-MM-DD");
    params.date_end = moment(dateParams.dateE, "DD-MM-YYYY").format("YYYY-MM-DD");
  }
  return params;
}
