// TO FIX
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import api, { apiAdmin, apiAuth, apiModules, setAuthToken } from "@/api";

export const state = () => ({
  user: localStorage.acc && JSON.parse(localStorage.acc),
  authToken: localStorage.token,
  forgotPassword: false,
  establishment: localStorage.esta && JSON.parse(localStorage.esta),
  establishmentInfos: localStorage.getItem("estaInfos")
    ? JSON.parse(localStorage.getItem("estaInfos"))
    : {},
  activitiesName: localStorage.getItem("activitiesName"),
});

export const mutations = {
  initData(state) {
    if (localStorage.getItem("estaInfos") && localStorage.getItem("estaInfos") !== "undefined") {
      state.establishmentInfos = JSON.parse(localStorage.getItem("estaInfos"));
    }
  },
  setAccount(data, acc) {
    if (acc) {
      localStorage.setItem("labels", "[]");
      localStorage.acc = JSON.stringify(acc);
    } else delete localStorage.acc;
    data.user = acc;
  },
  setAuthToken(data, token) {
    setAuthToken(token);
    data.authToken = token;
  },
  changeForgotPassword(data, forgotPwd) {
    data.forgotPassword = forgotPwd;
  },
  changeEstablishment(data, esta) {
    if (esta) localStorage.esta = JSON.stringify(esta);
    else delete localStorage.esta;

    data.establishment = esta;
  },
  setEstablishmentInfos(data, estaInfos) {
    if (estaInfos) localStorage.estaInfos = JSON.stringify(estaInfos);
    else delete localStorage.estaInfos;
    data.establishmentInfos = estaInfos;
  },
  getModuleActivitiesName(data, modules) {
    if (modules.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const module of modules) {
        if (module.module_name === "Patgames") {
          if (module.module_theme.name === "Patgames") {
            data.activitiesName = "PAT Games";
            localStorage.setItem("activitiesName", "PAT Games");
          } else {
            data.activitiesName = module.module_theme.name;
            localStorage.setItem("activitiesName", module.module_theme.name);
          }
        }
      }
    } else {
      data.activitiesName = "PAT Games";
      localStorage.setItem("activitiesName", "PAT Games");
    }
  },
};

export const actions = {
  changeForgotPassword({ commit }, forgotPwd) {
    commit("changeForgotPassword", forgotPwd);
  },
  async login({ commit }, params) {
    const result = await api.post(`${apiAuth}/loginsite`, params);
    commit("setAccount", result.data);
    commit("setAuthToken", result.headers["x-auth-token"]);
    return result;
  },
  async changeEstablishment({ commit }, esta) {
    const result = await api.post(`${apiAuth}/changeEstablishment`, { esta_id: esta });

    commit("changeEstablishment", result.data);
    return result;
  },
  async changeConsent({ commit }, user) {
    const result = await api.post(`${apiAuth}/setConsent`, {
      acc_id: user.acc_id,
      acc_consent: user.acc_consent,
      acc_consent_market: user.acc_consent_market,
    });
    commit("setAccount", user);
    return result;
  },
  async getInfosEstablishment({ commit }) {
    const result = await api.post(`${apiAdmin}/getInfosEstablishement`, {});

    commit("setEstablishmentInfos", result.data);
    return result;
  },
  async getIfEmailExist(_, stafEmail) {
    const result = await api.post(`${apiAdmin}/getIfEmailExist`, { acc_login: stafEmail });
    return result.data;
  },
  async changePassword(_, params) {
    const result = await api.post(`${apiAuth}/changePassword`, params);
    return result;
  },
  async resetPassword(_, params) {
    const result = await api.post(`${apiAuth}/resetPassword`, params);
    return result;
  },
  async resetGenericPassword(_, params) {
    const result = await api.post(`${apiAuth}/resetGenericPassword`, params);
    return result;
  },
  async initPassword(_, params) {
    const result = await api.post(`${apiAuth}/InitPassword`, params);
    return result;
  },
  async validateResetPassword({ commit }, params) {
    const result = await api.post(`${apiAuth}/validateresetpassword`, params);
    commit("setAccount", result.data);
    commit("setAuthToken", result.headers["x-auth-token"]);

    return result;
  },
  async setModuleActivitiesName({ commit }) {
    const result = await api.post(`${apiModules}/getThemeFromWhiteList`, {});
    commit("getModuleActivitiesName", result.data);
    return result.data;
  },
};
