/* eslint-disable no-param-reassign */
import api, { apiModules } from "@/api";

export const state = () => ({
  moduleAccess: localStorage.getItem("moduleAccess"),
  modulesWhiteList: localStorage.getItem("modulesWhiteList"),
  moduleWhiteListTheme: localStorage.getItem("moduleWhiteListTheme"),
});

export const mutations = {
  getModuleAccess(data, moduleAccess) {
    data.moduleAccess = moduleAccess;
    localStorage.setItem("moduleAccess", moduleAccess);
  },
  getModulesWhitelist(data, modulesWhiteList) {
    data.modulesWhiteList = modulesWhiteList;
    localStorage.setItem("modulesWhiteList", modulesWhiteList);
  },
  getThemeFromWhiteList(data, moduleWhiteListTheme) {
    moduleWhiteListTheme.forEach(
      // eslint-disable-next-line no-return-assign
      (m) => (m.module_name = m.module_theme.name ? m.module_theme.name : m.module_name),
    );
    data.moduleWhiteListTheme = moduleWhiteListTheme;

    localStorage.setItem("moduleWhiteListTheme", moduleWhiteListTheme);
  },
};

export const actions = {
  async getModuleAccess({ commit }) {
    const result = await api.post(`${apiModules}/getmoduleaccess`, {});
    commit("getModuleAccess", result.data);
    return result.data;
  },
  async getModulesWhitelist({ commit }) {
    const result = await api.post(`${apiModules}/getmodulewhitelist`, {});
    commit("getModulesWhitelist", result.data);
    return result.data;
  },
  async setModuleAccess(_, formData) {
    const { data } = await api.post(`${apiModules}/setmoduleaccess`, formData);
    return data;
  },
  async getThemeFromWhiteList({ commit }) {
    const result = await api.post(`${apiModules}/getThemeFromWhiteList`, {});
    commit("getThemeFromWhiteList", result.data);
    return result.data;
  },
};
