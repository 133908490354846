/* eslint-disable no-param-reassign */
import api, { apiEval } from "@/api";

export const state = () => ({
  evalCentreInteretResult: null,
});

export const mutations = {
  // eslint-disable-next-line no-return-assign
  setEvalCentreInteretResult: (data, val) => (data.evalCentreInteretResult = val),
};

export const actions = {
  async getEvalCentreInteretResult({ commit }, params) {
    commit("setEvalCentreInteretResult", null);
    const result = await api.post(`${apiEval}/getEvalCentreInteretResult`, params);
    commit("setEvalCentreInteretResult", result.data);
    return result;
  },
};
