import * as apiGlobal from "@/api/global";

let labels;
let pages;

export async function GetAllLabels() {
  if (!labels) labels = await apiGlobal.getAllLabels();
  return labels;
}

export async function GetPagesMaintenance() {
  // if (!pages)
  pages = await apiGlobal.getPagesMaintenance();
  return pages;
}
