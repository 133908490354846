/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import api, { apiAdmin, apiModules } from "@/api";
import { numberDayReportLock } from "@/pages/reports/config";

const moment = require("moment-timezone");

moment.tz.setDefault("Europe/London");

export const state = () => ({
  listReports: null,
  updateDates: {
    wellbeing_update: null,
  },
  slidingPeriodReport: 3,
  report: undefined,
  reportActivity: [],
  avgGlobal: {
    avgConcentration: 0,
    avgComprehension: 0,
    avgCoordination: 0,
    avgValorisation: 0,
    avgSatisfaction: 0,
    avgNbConcentration: 0,
    avgNbComprehension: 0,
    avgNbCoordination: 0,
    avgNbValorisation: 0,
    avgNbSatisfaction: 0,
  },
  avgPatGames: {
    avgConcentration: 0,
    avgComprehension: 0,
    avgCoordination: 0,
    avgValorisation: 0,
    avgSatisfaction: 0,
    avgNbConcentration: 0,
    avgNbComprehension: 0,
    avgNbCoordination: 0,
    avgNbValorisation: 0,
    avgNbSatisfaction: 0,
  },
  avgExternal: {
    avgConcentration: 0,
    avgComprehension: 0,
    avgCoordination: 0,
    avgValorisation: 0,
    avgSatisfaction: 0,
    avgNbConcentration: 0,
    avgNbComprehension: 0,
    avgNbCoordination: 0,
    avgNbValorisation: 0,
    avgNbSatisfaction: 0,
  },
  troubles: [],
  updateInfosReport: false,
  reportObservation: "",
  memberReportObservation: "",
  memberReportResults: [],
  memberReportTroubleId: null,
  memberReportSessDateStart: "",
  memberReportSessDateEnd: "",
  memberReportSessService: "",
  memberReportSessAnimationPlace: "",
});

export const mutations = {
  setUpdateDates: (data, val) => (data.updateDates = val),
  setReports: (data, val) =>
    (data.listReports = val
      ? val.sort(
          (a, b) => moment(b.sess_date_start).valueOf() - moment(a.sess_date_start).valueOf(),
        )
      : null),
  setReport: (data, val) => (data.report = val),
  setReportActivity: (data, val) => (data.reportActivity = val),
  setTroubles: (data, val) => (data.troubles = val),
  changeSlidingPeriodReport: (data, val) => (data.slidingPeriodReport = val),
  setAvgGlobal: (data, val) => (data.avgGlobal = val),
  setAvgPatGames: (data, val) => (data.avgPatGames = val),
  setAvgExternal: (data, val) => (data.avgExternal = val),
  updateReport: (data, val) => (data.updateInfosReport = val),
  setReportObservation: (data, val) => (data.reportObservation = val),
  setMemberReportObservation: (data, val) => (data.memberReportObservation = val),
  setMemberReportResults: (data, val) => (data.memberReportResults = val),
  setMemberReportTrouble: (data, val) => (data.memberReportTroubleId = val),
  setMemberReportSessionDateStart: (data, val) => (data.memberReportSessDateStart = val),
  setMemberReportSessionDateEnd: (data, val) => (data.memberReportSessDateEnd = val),
  setMemberReportSessionService: (data, val) => (data.memberReportSessService = val),
  setMemberReportSessionAnimationPlace: (data, val) => (data.memberReportSessAnimationPlace = val),
  archiveSession: (data, val) => {
    if (!data.listReports) return;

    const session = data.listReports.find((e) => e.sess_id === val);

    if (!session) return;
    session.sess_archived = true;
  },
  restoreSession: (data, val) => {
    if (!data.listReports) return;
    const session = data.listReports.find((e) => e.sess_id === val);

    if (!session) return;
    session.sess_archived = false;
  },
};

export const getters = {};

export const actions = {
  async initAVG({ commit }, listReports) {
    let avgGlobal = initAvg({});
    let avgPatGames = initAvg({});
    let avgExternal = initAvg({});
    let nbGlobal = 0;
    let nbPatGames = 0;
    let nbExternal = 0;

    for (let i = 0; i < listReports.length; i += 1) {
      const sessionType = listReports[i].sess_type;
      avgGlobal = sumAvgReadable(avgGlobal, listReports[i]);
      nbGlobal += 1;
      if (sessionType === 3 || sessionType === 5) {
        avgPatGames = sumAvgReadable(avgPatGames, listReports[i]);
        nbPatGames += 1;
      } else {
        avgExternal = sumAvgReadable(avgExternal, listReports[i]);
        nbExternal += 1;
      }
    }

    if (nbGlobal === 0) {
      commit("setAvgGlobal", avgGlobal);
    } else {
      commit("setAvgGlobal", computeAvg(avgGlobal, nbGlobal));
    }
    if (nbPatGames === 0) {
      commit("setAvgPatGames", avgPatGames);
    } else {
      commit("setAvgPatGames", computeAvg(avgPatGames, nbPatGames));
    }
    if (nbExternal === 0) {
      commit("setAvgExternal", avgExternal);
    } else {
      commit("setAvgExternal", computeAvg(avgExternal, nbExternal));
    }
  },
  async getTroubles({ commit }) {
    const res = await api.post(`${apiAdmin}/gettroubles`, {});
    const troubleData = [];
    for (let i = 0; i < res.data.length; i += 1) {
      const trouble = res.data[i];
      troubleData.push({
        label: trouble.trouble_name,
        value: trouble.tro_id,
      });
    }
    commit("setTroubles", troubleData);
  },
  async getReport({ commit }, id) {
    const res = await api.post(`${apiModules}/getreport`, { rep_id: parseInt(id, 10) });
    // eslint-disable-next-line prefer-destructuring
    if (res.data.length === 1) res.data = res.data[0];
    const today = moment().format("DD-MM-YYYY");
    const reportDate = moment(res.data.sess_date_start).format("DD-MM-YYYY");
    res.data.locked =
      moment(today, "DD-MM-YYYY").diff(moment(reportDate, "DD-MM-YYYY"), "days") <=
      numberDayReportLock;
    commit("setReport", res.data);
    return res.data;
  },
  async getReportActvityPartForSession({ commit }, id) {
    const res = await api.post(`${apiModules}/GetReportActivityPartForSession`, {
      repid: parseInt(id, 10),
    });
    commit("setReportActivity", res.data);
    return res.data;
  },
  async setReportActvityPartForSession({ commit }, activity) {
    const res = await api.post(`${apiModules}/setreportactivityparticipant`, {
      rapId: parseInt(activity.rap_id, 10),
      rapScore: parseInt(activity.rap_score, 10),
      acsId: activity.acs_id,
      parId: activity.par_id,
    });

    commit("setReportActivity", res.data);
    return res.data;
  },
  async updateSessionByReport(sess) {
    const res = await api.post(`${apiModules}/setsessions`, [sess]);
    return res.data;
  },
  async archiveSession({ commit }, sess) {
    const res = await api.post(`${apiAdmin}/SetSessionArchive`, {
      sess_id: sess,
      archived: true,
    });
    commit("archiveSession", sess);
    return res.data;
  },
  async restoreSession({ commit }, sess) {
    const res = await api.post(`${apiAdmin}/SetSessionArchive`, {
      sess_id: sess,
      archived: false,
    });
    commit("restoreSession", sess);
    return res.data;
  },
  changeSlidingPeriodReport({ commit }, data) {
    commit("changeSlidingPeriodReport", data);
    return data;
  },
  async createReport(sessionReport) {
    const sessionActivities = [];
    const participantsSession = [];
    const participantsReport = [];
    for (let i = 0; i < sessionReport.participants.length; i += 1) {
      const participant = sessionReport.participants[i];
      participantsSession.push(participant.par_id);
      participantsReport.push({
        id: participant.par_id,
        results: {
          0: 0,
          1: 0,
          2: 0,
          3: 0,
          4: 0,
        },
        obs: "",
      });
    }

    const staffs = [];
    for (let i = 0; i < sessionReport.staffs.length; i += 1) {
      const staff = sessionReport.staffs[i];
      staffs.push(staff.staf_id);
    }
    for (let i = 0; i < sessionReport.activities.length; i += 1) {
      const activity = sessionReport.activities[i];
      sessionActivities.push({
        acs_local_id: sessionReport.activities.indexOf(activity).toString(),
        acs_act_id: activity.act_id,
        acs_id: null,
        acs_date_start: sessionReport.dateStart,
        acs_date_end: sessionReport.dateEnd,
        acs_is_aborted: false,
        acs_is_planned: sessionReport.planned,
        acs_cae_id: activity.cae_id,
        participants: participantsSession,
        staffs,
      });
    }

    const sessionObject = {
      local_id: "0",
      sess_date_start: sessionReport.dateStart,
      sess_date_end: sessionReport.dateEnd,
      sess_sty_id: sessionReport.sessionType,
      sess_is_timeout: false,
      sess_shared_id: null,
      sess_serv_id: sessionReport.service,
      sess_meta: {},
      sess_anp_id: sessionReport.animatePlace,
      sess_type: sessionReport.sessionTypeName,
      sess_id: null,
      activities: sessionActivities,
    };
    const reportObject = {
      report: {
        date: sessionReport.dateStart,
        obs: "",
        id: null,
        is_ended: true,
        participants: participantsReport,
        staffs,
      },
    };
    // on créer la session
    const sessionRes = await api.post(`${apiModules}/setsessions`, [sessionObject]);
    if (sessionRes.data && sessionRes.data.length > 0) {
      if (sessionRes.data[0].activities && sessionRes.data[0].activities.length > 0) {
        const scoresReport = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const activity of sessionRes.data[0].activities) {
          const scores = [];
          // eslint-disable-next-line no-restricted-syntax
          for (const participant of activity.participants) {
            scores.push({
              local_id: participant.acspa_par_id,
              remote_id: null,
              score: 1,
              part_id: participant.acspa_par_id,
            });
          }
          const scoringReport = {
            acs_id: sessionRes.data[0].activities[0].acs_id,
            activity_report_score: scores,
          };
          scoresReport.push(scoringReport);
        }
      }
      reportObject.report.sessId = sessionRes.data[0].sess_id;
      // Nous créeon le report
      const reportRes = await api.post(`${apiModules}/setreports`, [reportObject]);
      return reportRes;
    }
    return null;
  },
  async updateReport({ commit }, report) {
    const res = await api.post(`${apiModules}/setreports`, [report]);

    if (res.data) commit("updateReport", true);

    return res.data;
  },
  changeUpdateInfosReport({ commit }) {
    commit("updateReport", false);
    return true;
  },
  saveReportObservation({ commit }, obs) {
    commit("setReportObservation", obs);
    return obs;
  },
  saveMemberReportObservation({ commit }, obs) {
    commit("setMemberReportObservation", obs);
    return obs;
  },
  saveMemberReportResults({ commit }, results) {
    commit("setMemberReportResults", results);
    return results;
  },
  saveMemberReportTrouble({ commit }, results) {
    commit("setMemberReportTrouble", results);
    return results;
  },
  saveReportSessionDateStart({ commit }, results) {
    commit("setMemberReportSessionDateStart", results);
    return results;
  },
  saveReportSessionDateEnd({ commit }, results) {
    commit("setMemberReportSessionDateEnd", results);
    return results;
  },
  saveReportSessionService({ commit }, results) {
    commit("setMemberReportSessionService", results);
    return results;
  },
  saveReportSessionAnimationPlace({ commit }, results) {
    commit("setMemberReportSessionAnimationPlace", results);
    return results;
  },
  async regenerateAVG({ commit }, reports) {
    let avgGlobal = initAvg({});
    let avgPatGames = initAvg({});
    let avgExternal = initAvg({});
    let nbGlobal = 0;
    let nbPatGames = 0;
    let nbExternal = 0;

    for (let i = 0; i < reports.length; i += 1) {
      const report = reports[i];
      avgGlobal = sumAvgReadable(avgGlobal, report);
      nbGlobal += 1;
      if (report.sess_type === 3 || report.sess_type === 5) {
        avgPatGames = sumAvgReadable(avgPatGames, report);
        nbPatGames += 1;
      } else {
        avgExternal = sumAvgReadable(avgExternal, report);
        nbExternal += 1;
      }
    }
    if (nbGlobal === 0) {
      commit("setAvgGlobal", avgGlobal);
    } else {
      commit("setAvgGlobal", computeAvg(avgGlobal, nbGlobal));
    }
    if (nbPatGames === 0) {
      commit("setAvgPatGames", avgPatGames);
    } else {
      commit("setAvgPatGames", computeAvg(avgPatGames, nbPatGames));
    }
    if (nbExternal === 0) {
      commit("setAvgExternal", avgExternal);
    } else {
      commit("setAvgExternal", computeAvg(avgExternal, nbExternal));
    }
    return reports;
  },
};

export function initAvg(avg) {
  avg.avgValorisation = 0;
  avg.avgSatisfaction = 0;
  avg.avgComprehension = 0;
  avg.avgCoordination = 0;
  avg.avgConcentration = 0;
  avg.avgNbConcentration = 0;
  avg.avgNbComprehension = 0;
  avg.avgNbCoordination = 0;
  avg.avgNbValorisation = 0;
  avg.avgNbSatisfaction = 0;

  return avg;
}

export function sumAvg(avg, res) {
  if (res["3"] !== 0) {
    avg.avgNbValorisation += 1;
    avg.avgValorisation += res["3"];
  }
  if (res["4"] !== 0) {
    avg.avgNbSatisfaction += 1;
    avg.avgSatisfaction += res["4"];
  }
  if (res["1"] !== 0) {
    avg.avgNbComprehension += 1;
    avg.avgComprehension += res["1"];
  }
  if (res["2"] !== 0) {
    avg.avgNbCoordination += 1;
    avg.avgCoordination += res["2"];
  }
  if (res["0"] !== 0) {
    avg.avgNbConcentration += 1;
    avg.avgConcentration += res["0"];
  }
  return avg;
}

export function sumAvgReadable(avg, res) {
  if (res.valorisation !== 0) {
    avg.avgNbValorisation += 1;
    avg.avgValorisation += res.valorisation;
  }
  if (res.satisfaction !== 0) {
    avg.avgNbSatisfaction += 1;
    avg.avgSatisfaction += res.satisfaction;
  }
  if (res.comprehension !== 0) {
    avg.avgNbComprehension += 1;
    avg.avgComprehension += res.comprehension;
  }
  if (res.coordination !== 0) {
    avg.avgNbCoordination += 1;
    avg.avgCoordination += res.coordination;
  }
  if (res.concentration !== 0) {
    avg.avgNbConcentration += 1;
    avg.avgConcentration += res.concentration;
  }
  return avg;
}

export function computeAvg(avg) {
  avg.avgValorisation =
    avg.avgNbValorisation !== 0
      ? Math.trunc((avg.avgValorisation / avg.avgNbValorisation) * 10) / 10
      : 0;
  avg.avgSatisfaction =
    avg.avgNbSatisfaction !== 0
      ? Math.trunc((avg.avgSatisfaction / avg.avgNbSatisfaction) * 10) / 10
      : 0;
  avg.avgComprehension =
    avg.avgNbComprehension !== 0
      ? Math.trunc((avg.avgComprehension / avg.avgNbComprehension) * 10) / 10
      : 0;
  avg.avgCoordination =
    avg.avgNbCoordination !== 0
      ? Math.trunc((avg.avgCoordination / avg.avgNbCoordination) * 10) / 10
      : 0;
  avg.avgConcentration =
    avg.avgNbConcentration !== 0
      ? Math.trunc((avg.avgConcentration / avg.avgNbConcentration) * 10) / 10
      : 0;
  return avg;
}
