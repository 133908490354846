import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const app = () => import("../pages/index.vue");
const home = () => import("../pages/newhome.vue");
const users = () => import("../pages/users/users.vue");
const calendar = () => import("../pages/planningV2/index.vue");
const print = () => import("../pages/print/index.vue");
const reports = () => import("../pages/reports/reports.vue");
const collectiveReport = () => import("../pages/reports/collectiveReport.vue");
const individualReport = () => import("../pages/reports/individualReport.vue");
const statsGeneral = () => import("../pages/stats/etablishment.vue");
const maintenance = () => import("../pages/maintenance.vue");
const statsParticipants = () => import("../pages/stats/StatistiquesParticipants.vue");
const statsSupervisors = () => import("../pages/stats/Supervisors.vue");
const logout = () => import("../pages/logout.vue");
const login = () => import("../pages/login.vue");
const changePassword = () => import("../pages/changePassword.vue");
const statsParticipantSocial = () => import("../pages/users/details/ParticipantSocialStats.vue");

const statsParticipantSante = () =>
  import("../pages/users/details/StatistiquesParticipantsSante.vue");
const statsSupervisor = () => import("../pages/users/details/StatistiquesSupervisor.vue");
const accountMenu = () => import("../pages/account/accountMenu.vue");
const accountAccess = () => import("../pages/account/accountAccess.vue");
const accountGroup = () => import("../pages/account/accountGroup.vue");
const accountExternalActivities = () => import("../pages/account/accountActivities.vue");
const accountActivitiesGoals = () => import("../pages/account/accountActivitiesGoals.vue");
const accountPin = () => import("../pages/account/accountPin.vue");
const accountServices = () => import("../pages/account/accountServices.vue");
const accountCustomData = () => import("../pages/account/accountCustomData.vue");
const accountMeloviePinCode = () => import("../pages/account/accountMeloviePinCode.vue");
const redirect = () => import("../pages/redirect.vue");
const Ans404 = () => import("../components/global/Ans404.vue");
const printCalendar = () => import("../pages/printCalendar.vue");

function guardMyroute(to, from, next) {
  if (localStorage.getItem("estaInfos")) {
    next();
  } else {
    next("/logout");
  }
}

export default () => {
  return new Router({
    mode: "hash",
    base: "/",
    scrollBehavior: () => {
      if (document.getElementById("scrollable-content")) {
        document.getElementById("scrollable-content").scrollTop = 0;
      }
    },
    routes: [
      {
        path: "/",
        component: app,
        children: [
          {
            path: "",
            component: home,
            name: "index",
            beforeEnter: guardMyroute,
          },
          {
            path: "stats",
            component: statsGeneral,
            name: "index-stats",
          },
          {
            path: "stats/members",
            component: statsParticipants,
            name: "index-stats-members",
          },
          {
            path: "stats/supervisors",
            component: statsSupervisors,
            name: "index-stats-supervisors",
          },
          {
            path: "users",
            component: users,
            name: "index-users",
          },
          {
            path: "users/stats/member/social/:id",
            component: statsParticipantSocial,
            name: "index-users-stats",
          },
          {
            path: "users/stats/member/sante/:id",
            component: statsParticipantSante,
            name: "index-users-stats-sante",
          },
          {
            path: "users/stats/sup/:id",
            component: statsSupervisor,
            name: "index-users-supstats",
          },
          {
            path: "planning",
            component: calendar,
            name: "index-calendar",
          },
          {
            path: "print",
            component: print,
            name: "index-print",
          },
          {
            path: "account",
            component: accountMenu,
            name: "index-account",
          },
          {
            path: "account/access",
            component: accountAccess,
            name: "account-access",
          },
          {
            path: "account/group",
            component: accountGroup,
            name: "account-group",
          },
          {
            path: "account/activitieslibrary",
            component: accountExternalActivities,
            name: "account-activities-library",
          },
          {
            path: "account/activitiesGoals",
            component: accountActivitiesGoals,
            name: "account-activities-goals",
          },
          {
            path: "account/pin",
            component: accountPin,
            name: "account-pin",
          },
          {
            path: "account/services",
            component: accountServices,
            name: "account-services",
          },
          {
            path: "account/customData",
            component: accountCustomData,
            name: "account-custom-data",
          },
          {
            path: "account/meloviePinCode",
            component: accountMeloviePinCode,
            name: "account-melovie-pin-code",
          },
          {
            path: "reports",
            component: reports,
            name: "index-reports",
          },
          {
            path: "reports/:id",
            component: collectiveReport,
            name: "index-report",
          },
          {
            path: "reports/:id/:member",
            component: individualReport,
            name: "index-report-member",
          },
          {
            path: "reports/flash/:id/:member",
            component: individualReport,
            name: "index-report-member-flash",
          },
          {
            path: "/404",
            component: Ans404,
            name: "Ans404",
            props: true,
          },
          {
            path: "maintenance",
            component: maintenance,
            name: "maintenance",
            props: true,
          },
        ],
      },
      {
        path: "/logout",
        component: logout,
        name: "logout",
      },
      {
        path: "/login",
        component: login,
        name: "login",
      },
      {
        path: "/changePassword",
        component: changePassword,
        name: "changePassword",
      },
      {
        path: "/redirect/:path",
        component: redirect,
        name: "redirect",
      },
      {
        path: "/print-calendar",
        component: printCalendar,
        name: "print-calendar",
      },
    ],

    fallback: false,
  });
};
