import api, { apiAdmin } from "@/api";

export async function getAllLabels() {
  const { data } = await api.post(`${apiAdmin}/GetAllLabels`, { type: "SDG" });
  return data;
}

// Récupération des pages en maintenance
export async function getPagesMaintenance() {
  const { data } = await api.post(`${apiAdmin}/site/getPagesMaintenance`, {});

  return data;
}
